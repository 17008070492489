@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600&display=swap');
@import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
@import 'react-calendar/dist/Calendar.css';

::-webkit-scrollbar {
  width: 8px;
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #9c9c9c ; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #525252 ; 
}

.App {
  text-align: center;
  position: relative;
}

.App-map {
  height: 100%;
  width: 100%;
}
.pac-container {
  z-index: 1351 !important;
}
App {
  text-align: center;
  height: 100%;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

td, tr{
  max-width:100%;
  white-space:nowrap;
}

.MuiDataGrid-sortIcon, .MuiDataGrid-menuIconButton{
  color: #fff !important;
}

.treeview > .toggle {
  pointer-events: all;
  cursor: pointer;
}
.treeview > .toggle:hover {
  color: #63b1de;
  fill: #63b1de;
}

.date-range{
  .react-daterange-picker__wrapper{
    border-radius: 3px;
    height: 38px;
    border: 1px solid #c4c4c4;
    .react-daterange-picker__inputGroup{
      min-width: auto;
      input{
        outline: none;
        background: transparent;
        color: #000;
      }
    }
  }
  .react-calendar__navigation__arrow
  {
    color: #666666;
    font-size: 24px;
  }
  .react-calendar__navigation__label__labelText{
    color: #666666;
    font-size: 17px;
  }
  .react-calendar__tile--active,
  .react-calendar__tile--active:enabled:hover,
  .react-calendar__tile--active:enabled:focus {
    background: #b20b01;
  }
  .react-calendar__tile--now,
  .react-calendar__tile--now:enabled:hover,
  .react-calendar__tile--now:enabled:focus{
    background: #fed8d6;
  }
  .react-calendar__tile--now {
    background: #fed8d6;
  }
  .react-calendar__navigation{
    margin: 0;
  }
  .react-calendar__month-view__weekdays__weekday{
    color: #b20b01;
    abbr[title] {
      text-decoration: none;
    }
  }
}
